import React, { useEffect, useLayoutEffect, useState, useRef } from "react"
import gql from "graphql-tag"
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks"
import { Helmet } from "react-helmet"
import { withFormik, Form, Field, useField } from "formik"
import * as Yup from "yup"
import NumberFormat from "react-number-format"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { useMediaPredicate } from "react-media-hook"
import Modal from "react-modal"
import { InstagramEmbed, TikTokEmbed } from 'react-social-media-embed';

import SEO from "../../../components/seo"
import Layout from "../../../components/layout"
import { Section, Container } from "../../../components/grid/grid"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const GET_USERDATA = gql`
  query formWvc2023 {
    formWvc2023 {
      fullName
      phoneNumber
      email
      category
      subCategory
      detailCategory
      title
      description
      link_website_blogspot
      gdrive_file_document
      link_embed_video
    }
  }
`

const UPDATE_SUBMISSION = gql`
  mutation updateWvc(
    $fullName: String
    $phoneNumber: String 
    $email: String
    $category: String
    $subCategory: String
    $detailCategory: String
    $title: String
    $description: String
    $link_website_blogspot: String
    $gdrive_file_document: String
    $link_embed_video: String
    ) {
    updateWvc(input: {
      clientMutationId: ""
      fullName: $fullName
      phoneNumber: $phoneNumber
      email: $email
      category: $category
      subCategory: $subCategory
      detailCategory: $detailCategory
      title: $title
      description: $description
      link_website_blogspot: $link_website_blogspot
      gdrive_file_document: $gdrive_file_document
      link_embed_video: $link_embed_video
    }) {
      fullName
      phoneNumber
      email
      category
      subCategory
      detailCategory
      title
      description
      link_website_blogspot
      gdrive_file_document
      link_embed_video
      message
      status
    }
  }
`

const SubmissionWvc2023Page = (location) =>{
  const staticData = useStaticQuery(graphql`
    query {
      wordPress {
        page(id: "wvc2023", idType: URI) {
          title
          featuredImage {
            mediaItemUrl
          }
          seo {
            title
            twitterTitle
            twitterDescription
            metaDesc
            opengraphTitle
            opengraphDescription
            focuskw
          }
        }
      }
    }
  `)
 
  const  { data: userData } = useQuery(GET_USERDATA)
  const [getUser,
    { 
      data: userRegis, 
      loading: loadingUserRegis 
    },
  ] = useLazyQuery(GET_USERDATA)

  const [updateSubmission, { loading: updateSubmissionLoading }] = useMutation(UPDATE_SUBMISSION)
  
  const [warningModal, setWarningModal] = useState(false) 

  const wordPress = staticData.wordPress?.page
  const seo = wordPress?.seo
  const featuredImage = wordPress?.featuredImage?.mediaItemUrl
  
  const resizeScreen = useMediaPredicate("(max-width: 992px)")
  // const [category, setCategory] = useState("")
  // const [subCategory, setSubCategory] = useState("")
  // const [detailCategory, setDetailCategory] = useState("")
  const currentUrl = process.env.BASE_URL + location.pathname
  const allUsers = userRegis && userRegis.formWvc2023
  const firstLoad = useRef(true)
  const phoneNumberRef = useRef(null)
  let userListingCategory = []
  const currentUser = 
    allUsers !== undefined && 
    allUsers.find(x => x.phoneNumber === phoneNumberRef.current)

  const [category, setCategory] = useState(currentUser && currentUser.category)
  const [subCategory, setSubCategory] = useState("")
  const [detailCategory, setDetailCategory] = useState("")

  const [formValues, setFormValues] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    title: "",
    description: "",
    category: "",
    subCategory: "",
    detailCategory: "",
    link_website_blogspot: "",
    gdrive_file_document: "",
    link_embed_video: "",
  })

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  }

  const getCookie = (cname) => {
    let name = cname + "="
    let decodedCookie = decodeURIComponent(document.cookie)
    let ca = decodedCookie.split(';')
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ""
  }

  // allUsers !== undefined && allUsers.forEach(item => {
  //   userListing.push(item)
  // })

  const handleCloseWarningModal = () => {{
    window.location.href = "/wvc2023"
    setWarningModal(false)
  }}

  const LeadForm = ({ errors, touched, values, setFieldValue, btnLoading }) => {
    const phoneNumberHandler = (val, setFieldValue) =>
      setFieldValue("phoneNumber", val)

      const [embed, setEmbed] = useState("")

      const handleTitleChange = (e, setFieldValue) => {
        const newValue = e.target.value
        setFieldValue('title', newValue)
      }
 
      const handleGdriveChange = (event) => {
          setFieldValue("gdrive_file_document", event.currentTarget.value);
      };

      const handleEmbedChange = (event) => {
        setEmbed(event.target.value);
        setFieldValue("link_embed_video", event.currentTarget.value);
      };

      const handleCategoryChange = (value) => {
        setCategory(value)
      }

      const handleCheckboxChange = (value) => {
        handleCategoryChange(value)
        localStorage.setItem('selectedCategory', value);
      }

      useEffect(() => {
        console.log("LOG_CEK_CATEGORY : ", category)
        const storedCategory = localStorage.getItem('selectedCategory');
        if (storedCategory) {
          setCategory(storedCategory);
        }
      }, []);

      useEffect(() => {
        if (phoneNumberRef.current) {
          const nameUser = 
            allUsers !== undefined && 
            allUsers.find(x => x.phoneNumber === phoneNumberRef.current).fullName
          const emailUser = 
            allUsers !== undefined && 
            allUsers.find(x => x.phoneNumber === phoneNumberRef.current).email
          const phoneUser = 
            allUsers !== undefined && 
            allUsers.find(x => x.phoneNumber === phoneNumberRef.current).phoneNumber
          const catUser = 
            allUsers !== undefined && 
            allUsers.find(x => x.phoneNumber === phoneNumberRef.current).category
          const subCatUser = 
            allUsers !== undefined && 
            allUsers.find(x => x.phoneNumber === phoneNumberRef.current).subCategory
          const detailCatUser = 
            allUsers !== undefined && 
            allUsers.find(x => x.phoneNumber === phoneNumberRef.current).detailCategory
          const titleUser = 
            allUsers !== undefined && 
            allUsers.find(x => x.phoneNumber === phoneNumberRef.current).title
          const descriptionUser = 
            allUsers !== undefined && 
            allUsers.find(x => x.phoneNumber === phoneNumberRef.current).description
          const linkUser = 
            allUsers !== undefined && 
            allUsers.find(x => x.phoneNumber === phoneNumberRef.current).link_website_blogspot
          const gdriveuser = 
            allUsers !== undefined && 
            allUsers.find(x => x.phoneNumber === phoneNumberRef.current).gdrive_file_document
          const embedUser = 
            allUsers !== undefined && 
            allUsers.find(x => x.phoneNumber === phoneNumberRef.current).link_embed_video

          const phoneTrim = phoneUser && phoneUser.substring(2,14).trim()

          allUsers !== undefined && allUsers.forEach(item => {
            if(item.phoneNumber === phoneNumberRef.current){
              userListingCategory.push(item.category)
            }
          })

          console.log("LOGGER_userListingCategory", userListingCategory)
          // setCategory(catUser)
          // setSubCategory(subCatUser)
          // setDetailCategory(detailCatUser)

          setFieldValue("name", nameUser)
          setFieldValue("email", emailUser)
          setFieldValue("phoneNumber", phoneTrim)
          setFieldValue("title", titleUser)
          setFieldValue("description", descriptionUser)
          setFieldValue("link_website_blogspot", linkUser)
          setFieldValue("gdrive_file_document", gdriveuser)
          setFieldValue("link_embed_video", embedUser)
          setFieldValue("category", catUser)
          setFieldValue("subCategory", subCatUser)
          setFieldValue("detailCategory", detailCatUser)

          if (category !== "") {
            setFieldValue("name", nameUser)
            setFieldValue("email", emailUser)
            setFieldValue("phoneNumber", phoneTrim)
            setFieldValue("title", titleUser)
            setFieldValue("description", descriptionUser)
            setFieldValue("category", category)
            if (category === "video") {
              setFieldValue("gdrive_file_document", "")
            }else{
              setFieldValue("link_embed_video", "")
            }
          }
        }
        
        // if (category !== "") {
        //   // console.log("logger2.0")
        //   setFieldValue("category", category)
        //   if (category === "video") {
        //     setFieldValue("link_embed_video", "")
        //   }
        // }

        if (subCategory !== "") {
          // console.log("logger3.0")
          setFieldValue("subCategory", subCategory)
        }

        if (detailCategory !== "") {
          setFieldValue("detailCategory", detailCategory)
        }

      }, [category, subCategory, detailCategory, allUsers])
  
    return (
      <Form className="pt-4 m-0" id="submissionwvc2023form">
        {phoneNumberRef.current ? (
          <div className="row has-data">
            <div className="col-12 col-md-6">
              <div className="col-12 col-md-12 pb-3">
                <label className="text-muted my-0">Nama</label>
                <Field
                  name="name"
                  className="form-control px-0"
                  placeholder="Nama Anda"
                  type="text"
                  id="customerName"
                />
                {touched.name && errors.name && (
                  <div className="invalid-value d-block">{errors.name}</div>
                )}
              </div>
              <div className="col-12 col-md-12 pb-3 d-none">
                <label className="text-muted my-0">Email</label>
                <Field
                  name="email"
                  className="form-control px-0 d-none"
                  placeholder="Email anda"
                  type="text"
                  id="customerEmail"
                />
              </div>
              <div className="col-12 col-md-12 pb-3">
                <label className="text-muted my-0">Nomor Handphone</label>
                <NumberFormat
                  name="phoneNumber"
                  className="form-control px-0"
                  placeholder="Nomor Handphone"
                  allowEmptyFormatting
                  prefix="+62"
                  value={values.phoneNumber}
                  onValueChange={(val) =>
                    phoneNumberHandler(val.formattedValue, setFieldValue)
                  }
                  id="customerPhone"
                />
                {touched.phoneNumber && errors.phoneNumber && (
                  <div className="invalid-value d-block">{errors.phoneNumber}</div>
                )}
              </div>
              <div className="col-12 col-md-12 pb-3">
                <label className="text-muted my-0">Title</label>
                <Field
                  name="title"
                  className="form-control px-0"
                  placeholder=""
                  type="text"
                  id="title"
                  value={values.title}
                  onChange={(e) => {
                    handleTitleChange(e, setFieldValue)
                  }}
                />
                {touched.title && errors.title && (
                  <div className="invalid-value d-block">{errors.title}</div>
                )}
              </div>
              <div className="col-12 col-md-12 pb-3">
                <label className="text-muted my-0">Description</label>
                <Field
                  as="textarea"
                  name="description"
                  className="form-control px-0"
                  placeholder=""
                  id="description"
                />
                {touched.description && errors.description && (
                  <div className="invalid-value d-block">{errors.description}</div>
                )}
              </div>
              <div className={`col-12 col-md-12 pb-3 ${category === "video" || values.category === "video" ? "d-none" : "d-block"}`}>
                <label className="text-muted my-0">Link</label>
                <Field
                  name="link_website_blogspot"
                  className="form-control px-0"
                  placeholder="Link Blogspot/Website"
                  type="text"
                  id="link_website_blogspot"
                />
                {touched.link_website_blogspot && errors.link_website_blogspot && (
                  <div className="invalid-value d-block">{errors.link_website_blogspot}</div>
                )}
              </div>
              <div className="row col-12 col-md-12 pb-3 pt-3">
                <div className="col-12 col-md-12 pb-2">
                  <label className="text-muted my-0">Kategori</label>
                </div>
                <div>
                  <div className="form-group">
                    <div className="custom-control custom-checkbox">
                      <input
                        name="category"
                        type="checkbox"
                        className="custom-control-input"
                        id="writing"
                        onChange={() => handleCheckboxChange('writing')}
                        checked={category === 'writing'}
                      />
                      <label className="custom-control-label" for="writing">
                        Writing
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="custom-control custom-checkbox">
                      <input
                        name="category"
                        type="checkbox"
                        className="custom-control-input"
                        id="video"
                        onChange={() => handleCheckboxChange('video')}
                        checked={category === 'video'}
                      />
                      <label className="custom-control-label" for="video">
                        Video
                      </label>
                    </div>
                  </div>
                </div>
                {/* <Field
                  name="category"
                  render={({ field }) => {
                    return (
                      <div className="d-flex mb-3 mb-lg-4">
                        <label className="radio-wrapper mr-4 mr-md-6">
                          Writing
                          <input
                            {...field}
                            id="writing"
                            type="radio"
                            checked={field.value === "writing"}
                            name="category"
                            value="writing"
                            onClick={() => {
                              setCategory("writing")
                              firstLoad.current = false
                            }}
                          />
                          <span className="radio-button" />
                        </label>
                        <label className="radio-wrapper">
                          Video
                          <input
                            {...field}
                            id="video"
                            type="radio"
                            checked={field.value === "video"}
                            name="category"
                            value="video"
                            onClick={() => {
                              setCategory("video")
                              firstLoad.current = false
                            }}
                          />
                          <span className="radio-button" />
                        </label>
                      </div>
                    )
                  }}
                /> */}
              </div>
              <div className={`row col-12 col-md-12 pb-3 pt-3 ${values.category === "writing" || category === "writing" ? "d-flex" : "d-none"}`} id="w-sub-category">
                <div className="col-12 col-md-12 pb-2">
                  <label className="text-muted my-0">Sub Kategori</label>
                </div>
                <Field
                  name="subCategory"
                  render={({ field }) => {
                    return (
                      <div className="d-flex mb-3 mb-lg-4">
                        <label className="radio-wrapper mr-4 mr-md-6">
                          Jurnalis
                          <input
                            {...field}
                            id="jurnalis"
                            type="radio"
                            checked={field.value === "jurnalis"}
                            name="subCategory"
                            value="jurnalis"
                            onClick={() => setSubCategory("jurnalis")}
                          />
                          <span className="radio-button" />
                        </label>
                        <label className="radio-wrapper">
                          Umum
                          <input
                            {...field}
                            id="w-umum"
                            type="radio"
                            checked={field.value === "w-umum"}
                            name="subCategory"
                            value="w-umum"
                            onClick={() => setSubCategory("w-umum")}
                          />
                          <span className="radio-button" />
                        </label>
                      </div>
                    )
                  }}
                />
              </div>
              <div className={`row col-12 col-md-12 pb-3 pt-3 ${values.category === "video" || category === "video" ? "d-flex" : "d-none"}`} id="v-sub-category">
                <div className="col-12 col-md-12 pb-2">
                  <label className="text-muted my-0">Sub Kategori</label>
                </div>
                {/* <div className="col-12 col-xs-6 col-sm-6 col-md-6">
                  <Field 
                    type="checkbox" 
                    name="subCategory" 
                    value="media"
                    id="media"
                    // checked={subCategory === "media"}
                    // onClick={() => setSubCategory("media")}
                    checked = {subCategoryRef.current = "media"}
                    ref = {subCategoryRef}
                    onClick={() => subCategoryRef.current = "media"}
                  />
                  &nbsp;<span>Media</span>
                </div>
                <div className="col-12 col-xs-6 col-sm-6 col-md-6">
                <Field 
                    type="checkbox" 
                    name="subCategory" 
                    value="umum"
                    id="v-umum"
                    // checked={subCategory === "v-umum"}
                    // onClick={() => setSubCategory("v-umum")}
                    checked = {subCategoryRef.current = "v-umum"}
                    ref = {subCategoryRef}
                    onClick={() => subCategoryRef.current = "v-umum"}
                  />
                  &nbsp;<span>Umum</span>
                </div> */}
                <Field
                  name="subCategory"
                  render={({ field }) => {
                    return (
                      <div className="d-flex mb-3 mb-lg-4">
                        <label className="radio-wrapper mr-4 mr-md-6">
                          Media
                          <input
                            {...field}
                            id="media"
                            type="radio"
                            checked={field.value === "media"}
                            name="subCategory"
                            value="media"
                            onClick={() => setSubCategory("media")}
                          />
                          <span className="radio-button" />
                        </label>
                        <label className="radio-wrapper">
                          Umum
                          <input
                            {...field}
                            id="v-umum"
                            type="radio"
                            checked={field.value === "v-umum"}
                            name="subCategory"
                            value="v-umum"
                            onClick={() => setSubCategory("v-umum")}
                          />
                          <span className="radio-button" />
                        </label>
                      </div>
                    )
                  }}
                />
              </div>
              {touched.subCategory && errors.subCategory && (
                <div className="col-12 col-md-12">
                  <div className="invalid-value d-block">{errors.subCategory}</div>
                </div>
              )}
              {/* <div className="row col-12 col-md-12 pb-3 pt-3" id="w-detail-category">
                <div className="col-12 col-md-12 pb-2">
                  <label className="text-muted my-0">Kategori Detail</label>
                </div>
                <div className="col-12 col-xs-6 col-sm-6 col-md-6">
                  <Field 
                    type="checkbox" 
                    name="cetak" 
                    value="cetak"
                    id="cetak"
                    onClick={handleOnChange}
                  />
                  &nbsp;<span>Cetak (Majalah/Koran)</span>
                </div>
                <div className="col-12 col-xs-6 col-sm-6 col-md-6">
                <Field 
                    type="checkbox" 
                    name="online-news" 
                    value="online-news"
                    id="online-news"
                    onClick={handleOnChange}
                  />
                  &nbsp;<span>Online News</span>
                </div>
              </div> */}
              <div className={`row col-12 col-md-12 pb-3 pt-3 ${values.subCategory === "jurnalis" || values.subCategory === "w-umum" || subCategory === "jurnalis" || subCategory === "w-umum" ? "d-block" : "d-none"}`} id="w-detail-category">
                <div className="col-12 col-md-12 pb-2">
                  <label className="text-muted my-0">Kategori Detail</label>
                </div>
                {/* <div className={`col-12 col-xs-6 col-sm-6 col-md-6 pb-3 ${subCategory === "jurnalis" ? "d-block" : "d-none"}`}>
                  <Field 
                    type="checkbox" 
                    name="detailCategory" 
                    value="print"
                    id="print"
                    // checked={detailCategory === "print"}
                    // onClick={() => setDetailCategory("print")}
                    checked = {detailCategoryRef.current = "print"}
                    ref = {detailCategoryRef}
                    onClick={() => detailCategoryRef.current = "print"}
                  />
                  &nbsp;<span>Print(Majalah dan Koran)</span>
                </div>
                <div className={`col-12 col-xs-6 col-sm-6 col-md-6 pb-3 ${subCategoryRef.current === "jurnalis" ? "d-block" : "d-none"}`}>
                  <Field 
                    type="checkbox" 
                    name="detailCategory" 
                    value="online-news"
                    id="online-news"
                    // checked={detailCategory === "online-news"}
                    // onClick={() => setDetailCategory("online-news")}
                    checked = {detailCategoryRef.current = "online-news"}
                    ref = {detailCategoryRef}
                    onClick={() => detailCategoryRef.current = "online-news"}
                  />
                  &nbsp;<span>Online News</span>
                </div> */}
                <Field
                  name="detailCategory"
                  render={({ field }) => {
                    return (
                      <div className={`${values.subCategory === "jurnalis" || subCategory === "jurnalis" ? "d-flex" : "d-none"} mb-3 mb-lg-4`}>
                        <label className="radio-wrapper mr-4 mr-md-6">
                          Print(Majalah dan Koran)
                          <input
                            {...field}
                            id="print"
                            type="radio"
                            checked={field.value === "print"}
                            name="detailCategory"
                            value="print"
                            onClick={() => setDetailCategory("print")}
                          />
                          <span className="radio-button" />
                        </label>
                        <label className="radio-wrapper">
                          Online (Media Sosial)
                          <input
                            {...field}
                            id="online-news"
                            type="radio"
                            checked={field.value === "online-news"}
                            name="detailCategory"
                            value="online-news"
                            onClick={() => setDetailCategory("online-news")}
                          />
                          <span className="radio-button" />
                        </label>
                      </div>
                    )
                  }}
                />
                {/* <div className={`col-12 col-xs-6 col-sm-6 col-md-6 ${subCategoryRef.current === "w-umum" ? "d-block" : "d-none"}`}>
                <Field 
                    type="checkbox" 
                    name="detailCategory" 
                    value="blogger"
                    id="blogger"
                    // checked={detailCategory === "blogger"}
                    // onClick={() => setDetailCategory("blogger")}
                    checked = {detailCategoryRef.current = "blogger"}
                    ref = {detailCategoryRef}
                    onClick={() => detailCategoryRef.current = "blogger"}
                  />
                  &nbsp;<span>Blogger</span>
                </div> */}
                <Field
                  name="detailCategory"
                  render={({ field }) => {
                    return (
                      <div className={`${values.subCategory === "w-umum" || subCategory === "w-umum" ? "d-flex" : "d-none"} mb-3 mb-lg-4`}>
                        <label className="radio-wrapper mr-4 mr-md-6">
                          Blogger
                          <input
                            {...field}
                            id="blogger"
                            type="radio"
                            checked={field.value === "blogger"}
                            name="detailCategory"
                            value="blogger"
                            onClick={() => setDetailCategory("blogger")}
                          />
                          <span className="radio-button" />
                        </label>
                      </div>
                    )
                  }}
                />
              </div>
              <div className={`row col-12 col-md-12 pb-3 pt-3 ${values.subCategory === "media" || values.subCategory === "v-umum" || subCategory === "media" || subCategory === "v-umum" ? "d-block" : "d-none"}`} id="v-detail-category">
                <div className="col-12 col-md-12 pb-2">
                  <label className="text-muted my-0">Kategori Detail</label>
                </div>
                {/* <div className={`col-12 col-xs-6 col-sm-6 col-md-6 ${subCategoryRef.current === "media" ? "d-block" : "d-none"}`}>
                  <Field 
                    type="checkbox" 
                    name="detailCategory" 
                    value="online"
                    id="online-socmed"
                    // checked={detailCategory === "online-socmed"}
                    // onClick={() => setDetailCategory("online-socmed")}
                    checked = {detailCategoryRef.current = "online-socmed"}
                    ref = {detailCategoryRef}
                    onClick={() => detailCategoryRef.current = "online-socmed"}
                  />
                  &nbsp;<span>Online (Media Sosial)</span>
                </div> */}
                <Field
                  name="detailCategory"
                  render={({ field }) => {
                    return (
                      <div className={`${values.subCategory === "media" || subCategory === "media" ? "d-flex" : "d-none"} mb-3 mb-lg-4`}>
                        <label className="radio-wrapper mr-4 mr-md-6">
                        Online (Media Sosial)
                          <input
                            {...field}
                            id="online-socmed"
                            type="radio"
                            checked={field.value === "online-socmed"}
                            name="detailCategory"
                            value="online-socmed"
                            onClick={() => setDetailCategory("online-socmed")}
                          />
                          <span className="radio-button" />
                        </label>
                      </div>
                    )
                  }}
                />
                {/* <div className={`col-12 col-xs-6 col-sm-6 col-md-6 ${subCategoryRef.current === "v-umum" ? "d-block" : "d-none"}`}>
                <Field 
                    type="checkbox" 
                    name="detailCategory" 
                    value="content-creator"
                    id="content-creator"
                    // checked={detailCategory === "content-creator"}
                    // onClick={() => setDetailCategory("content-creator")}
                    checked = {detailCategoryRef.current = "content-creator"}
                    ref = {detailCategoryRef}
                    onClick={() => detailCategoryRef.current = "content-creator"}
                  />
                  &nbsp;<span>Content Creator: Instagram/Tiktok</span>
                </div> */}
                <Field
                  name="detailCategory"
                  render={({ field }) => {
                    return (
                      <div className={`${values.subCategory === "v-umum" || subCategory === "v-umum" ? "d-flex" : "d-none"} mb-3 mb-lg-4`}>
                        <label className="radio-wrapper mr-4 mr-md-6">
                        Content Creator: Instagram/Tiktok
                          <input
                            {...field}
                            id="content-creator"
                            type="radio"
                            checked={field.value === "content-creator"}
                            name="detailCategory"
                            value="content-creator"
                            onClick={() => setDetailCategory("content-creator")}
                          />
                          <span className="radio-button" />
                        </label>
                      </div>
                    )
                  }}
                />
              </div>
              {touched.detailCategory && errors.detailCategory && (
                <div className="col-12 col-md-12">
                  <div className="invalid-value d-block">{errors.detailCategory}</div>
                </div>
              )}
            </div>
            <div className={`col-12 col-md-6 ${values.category === "video" || category === "video" ? "d-none" : "d-block"}`}>
              {/* {values.foto ? (
                <div>
                  <p>Review Dokumen:</p>
                  <p>Nama File: {values.foto.name}</p>
                  <p>Ukuran File: {values.foto.size} bytes</p>
                </div>
              ):(
                <span className="d-block">Input File Dokumen</span>
              )}
              <input
                id="foto"
                name="foto"
                type="file"
                onChange={handleFileChange}
              />
              {touched.foto && errors.foto && (
                <div className="invalid-value d-block">{errors.foto}</div>
              )} */}
              <div className="col-12 col-md-12 pb-3">
                <label className="text-muted my-0">G-Drive URL</label>
                <Field
                  name="gdrive_file_document"
                  className="form-control px-0"
                  placeholder="G-Drive URL"
                  type="text"
                  id="gdrive_file_document"
                  // onChange={handleGdriveChange}
                />
                {touched.gdrive_file_document && errors.gdrive_file_document && (
                  <div className={`invalid-value ${values.category === "writing" || category === "writing" ? "d-block" : "d-none"}`}>{errors.gdrive_file_document}</div>
                )}
              </div>
            </div>
            <div className={`col-12 col-md-6 ${values.category === "video" || category === "video" ? "d-block" : "d-none"}`}>
              <div className="col-12 col-md-12 pb-3">
                <label className="text-muted my-0">Embed URL</label>
                <Field
                  name="link_embed_video"
                  className="form-control px-0"
                  placeholder="Embed URL"
                  type="text"
                  id="link_embed_video"
                  onChange={handleEmbedChange}
                />
                {touched.link_embed_video && errors.link_embed_video && (
                  <div className={`invalid-value ${values.category === "video" || category === "video" ? "d-block" : "d-none"}`}>{errors.link_embed_video}</div>
                )}
              </div>
              <div className="col-12 col-md-12 pb-3">
                {embed.includes("instagram.com") ? (
                  <InstagramEmbed
                    url={embed}
                    width={330}
                  />
                ) : embed.includes("tiktok.com") ? (
                  <TikTokEmbed
                    url={embed}
                    width={330}
                  />
                ) : (
                  <span>Masukkan URL Instagram atau TikTok yang valid.</span>
                )}
                {/* <div id="embedContainer"></div> */}
              </div>
            </div>
            <div className="col-12 pt-5">
              <button className="btn btn-primary w-100 w-md-25">
                Save
              </button>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="col-12 col-md-12 pb-3">
                <label className="text-muted my-0">Nama</label>
                <Field
                  name="name"
                  className="form-control px-0"
                  placeholder="Nama Anda"
                  type="text"
                  id="customerName"
                />
                {touched.name && errors.name && (
                  <div className="invalid-value d-block">{errors.name}</div>
                )}
              </div>
              <div className="col-12 col-md-12 pb-3">
                <label className="text-muted my-0">Nomor Handphone</label>
                <NumberFormat
                  name="phoneNumber"
                  className="form-control px-0"
                  placeholder="Nomor Handphone"
                  allowEmptyFormatting
                  prefix="+62"
                  value={values.phoneNumber}
                  onValueChange={(val) =>
                    phoneNumberHandler(val.formattedValue, setFieldValue)
                  }
                  id="customerPhone"
                />
                {touched.phoneNumber && errors.phoneNumber && (
                  <div className="invalid-value d-block">{errors.phoneNumber}</div>
                )}
              </div>
              <div className="col-12 col-md-12 pb-3">
                <label className="text-muted my-0">Title</label>
                <Field
                  name="title"
                  className="form-control px-0"
                  placeholder=""
                  type="text"
                  id="title"
                />
                {touched.title && errors.title && (
                  <div className="invalid-value d-block">{errors.title}</div>
                )}
              </div>
              <div className="col-12 col-md-12 pb-3">
                <label className="text-muted my-0">Description</label>
                <Field
                  as="textarea"
                  name="description"
                  className="form-control px-0"
                  placeholder=""
                  id="description"
                />
                {touched.description && errors.description && (
                  <div className="invalid-value d-block">{errors.description}</div>
                )}
              </div>
              <div className="col-12 col-md-12 pb-3">
                <label className="text-muted my-0">Link</label>
                <Field
                  name="link_website_blogspot"
                  className="form-control px-0"
                  placeholder="blogspot/website"
                  type="text"
                  id="link_website_blogspot"
                />
                {touched.link_website_blogspot && errors.link_website_blogspot && (
                  <div className="invalid-value d-block">{errors.link_website_blogspot}</div>
                )}
              </div>
              {/* <div className="row col-12 col-md-12 pb-3 pt-3" id="w-sub-category">
                <div className="col-12 col-md-12 pb-2">
                  <label className="text-muted my-0">Sub Kategori</label>
                </div>
                <div className="col-12 col-xs-6 col-sm-6 col-md-6">
                  <Field 
                    type="checkbox" 
                    name="jurnalis" 
                    value="jurnalis"
                    id="jurnalis"
                    onClick={handleOnChange}
                  />
                  &nbsp;<span>Jurnalis</span>
                </div>
                <div className="col-12 col-xs-6 col-sm-6 col-md-6">
                  <Field 
                      type="checkbox" 
                      name="w-umum" 
                      value="umum"
                      id="w-umum"
                      onClick={handleOnChange}
                    />
                  &nbsp;<span>Umum</span>
                </div>
              </div> */}
              <div className={`row col-12 col-md-12 pb-3 pt-3 ${category === "writing" ? "d-flex" : "d-none"}`} id="w-sub-category">
                <div className="col-12 col-md-12 pb-2">
                  <label className="text-muted my-0">Sub Kategori</label>
                </div>
                <div className="col-12 col-xs-6 col-sm-6 col-md-6">
                  <Field 
                    type="checkbox" 
                    name="subCategory" 
                    value="jurnalis"
                    id="jurnalis"
                    // checked={subCategory === "jurnalis"}
                    // onClick={() => setSubCategory("jurnalis")}
                  />
                  &nbsp;<span>Jurnalis</span>
                </div>
                <div className="col-12 col-xs-6 col-sm-6 col-md-6">
                  <Field 
                    type="checkbox" 
                    name="subCategory" 
                    value="umum"
                    id="w-umum"
                    // checked={subCategory === "w-umum"}
                    // onClick={() => setSubCategory("w-umum")}
                  />
                  &nbsp;<span>Umum</span>
                </div>
              </div>
              <div className={`row col-12 col-md-12 pb-3 pt-3 ${category === "video" ? "d-flex" : "d-none"}`} id="v-sub-category">
                <div className="col-12 col-md-12 pb-2">
                  <label className="text-muted my-0">Sub Kategori</label>
                </div>
                <div className="col-12 col-xs-6 col-sm-6 col-md-6">
                  <Field 
                    type="checkbox" 
                    name="subCategory" 
                    value="media"
                    id="media"
                    // checked={subCategory === "media"}
                    // onClick={() => setSubCategory("media")}
                  />
                  &nbsp;<span>Media</span>
                </div>
                <div className="col-12 col-xs-6 col-sm-6 col-md-6">
                <Field 
                    type="checkbox" 
                    name="subCategory" 
                    value="umum"
                    id="v-umum"
                    // checked={subCategory === "v-umum"}
                    // onClick={() => setSubCategory("v-umum")}
                  />
                  &nbsp;<span>Umum</span>
                </div>
              </div>
              {touched.subCategory && errors.subCategory && (
                <div className="col-12 col-md-12">
                  <div className="invalid-value d-block">{errors.subCategory}</div>
                </div>
              )}
              {/* <div className="row col-12 col-md-12 pb-3 pt-3" id="w-detail-category">
                <div className="col-12 col-md-12 pb-2">
                  <label className="text-muted my-0">Kategori Detail</label>
                </div>
                <div className="col-12 col-xs-6 col-sm-6 col-md-6">
                  <Field 
                    type="checkbox" 
                    name="cetak" 
                    value="cetak"
                    id="cetak"
                    onClick={handleOnChange}
                  />
                  &nbsp;<span>Cetak (Majalah/Koran)</span>
                </div>
                <div className="col-12 col-xs-6 col-sm-6 col-md-6">
                <Field 
                    type="checkbox" 
                    name="online-news" 
                    value="online-news"
                    id="online-news"
                    onClick={handleOnChange}
                  />
                  &nbsp;<span>Online News</span>
                </div>
              </div> */}
              <div className={`row col-12 col-md-12 pb-3 pt-3 ${subCategory === "jurnalis" || subCategory === "w-umum" ? "d-block" : "d-none"}`} id="w-detail-category">
                <div className="col-12 col-md-12 pb-2">
                  <label className="text-muted my-0">Kategori Detail</label>
                </div>
                <div className={`col-12 col-xs-6 col-sm-6 col-md-6 pb-3 ${subCategory === "jurnalis" ? "d-block" : "d-none"}`}>
                  <Field 
                    type="checkbox" 
                    name="detailCategory" 
                    value="print"
                    id="print"
                    // checked={detailCategory === "print"}
                    // onClick={() => setDetailCategory("print")}
                  />
                  &nbsp;<span>Print(Majalah dan Koran)</span>
                </div>
                <div className={`col-12 col-xs-6 col-sm-6 col-md-6 pb-3 ${subCategory === "jurnalis" ? "d-block" : "d-none"}`}>
                  <Field 
                    type="checkbox" 
                    name="detailCategory" 
                    value="online-news"
                    id="online-news"
                    // checked={detailCategory === "online-news"}
                    // onClick={() => setDetailCategory("online-news")}
                  />
                  &nbsp;<span>Online News</span>
                </div>
                <div className={`col-12 col-xs-6 col-sm-6 col-md-6 ${subCategory === "w-umum" ? "d-block" : "d-none"}`}>
                <Field 
                    type="checkbox" 
                    name="detailCategory" 
                    value="blogger"
                    id="blogger"
                    // checked={detailCategory === "blogger"}
                    // onClick={() => setDetailCategory("blogger")}
                  />
                  &nbsp;<span>Blogger</span>
                </div>
              </div>
              <div className={`row col-12 col-md-12 pb-3 pt-3 ${subCategory === "media" || subCategory === "v-umum" ? "d-block" : "d-none"}`} id="v-detail-category">
                <div className="col-12 col-md-12 pb-2">
                  <label className="text-muted my-0">Kategori Detail</label>
                </div>
                <div className={`col-12 col-xs-6 col-sm-6 col-md-6 ${subCategory === "media" ? "d-block" : "d-none"}`}>
                  <Field 
                    type="checkbox" 
                    name="detailCategory" 
                    value="online"
                    id="online-socmed"
                    // checked={detailCategory === "online-socmed"}
                    // onClick={() => setDetailCategory("online-socmed")}
                  />
                  &nbsp;<span>Online (Media Sosial)</span>
                </div>
                <div className={`col-12 col-xs-6 col-sm-6 col-md-6 ${subCategory === "v-umum" ? "d-block" : "d-none"}`}>
                <Field 
                    type="checkbox" 
                    name="detailCategory" 
                    value="content-creator"
                    id="content-creator"
                    // checked={detailCategory === "content-creator"}
                    // onClick={() => setDetailCategory("content-creator")}
                  />
                  &nbsp;<span>Content Creator: Instagram/Tiktok</span>
                </div>
              </div>
              {touched.detailCategory && errors.detailCategory && (
                <div className="col-12 col-md-12">
                  <div className="invalid-value d-block">{errors.detailCategory}</div>
                </div>
              )}
            </div>
            <div className="col-12 col-md-6">
              <div className="col-12 col-md-12 pb-3">
                <label className="text-muted my-0">G-Drive URL</label>
                <Field
                  name="gdrive_file_document"
                  className="form-control px-0"
                  placeholder="G-Drive URL"
                  type="text"
                  id="gdrive_file_document"
                  // onChange={handleGdriveChange}
                />
                {touched.gdrive_file_document && errors.gdrive_file_document && (
                  <div className={`invalid-value ${category === "video" ? "d-block" : "d-none"}`}>{errors.gdrive_file_document}</div>
                )}
              </div>
            </div>
            <div className="col-12 pt-5">
              <button disabled={updateSubmissionLoading} className="btn btn-primary w-100 w-md-25">
                {updateSubmissionLoading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        )}
      </Form>
    )
  }

  // const [formValues, setFormValues] = useState({
  //   name: "",
  //   phoneNumber: "",
  //   email: "",
  //   title: "",
  //   description: "",
  //   category: "",
  //   subCategory: "",
  //   detailCategory: "",
  //   link_website_blogspot: "",
  //   gdrive_file_document: "",
  //   link_embed_video: "",
  // })

  const EnhancedLeadForm = withFormik({
    mapPropsToValues({ 
      name, 
      phoneNumber, 
      email, 
      title, 
      description, 
      category, 
      subCategory, 
      detailCategory, 
      link_website_blogspot, 
      gdrive_file_document, 
      link_embed_video,
    }) {
      return {
        name: formValues.name || name,
        phoneNumber: formValues.phoneNumber || phoneNumber,
        email: formValues.email || email,
        title: formValues.title || title,
        description: formValues.description || description,
        category: formValues.category || category,
        subCategory: formValues.subCategory || subCategory,
        detailCategory: formValues.detailCategory || detailCategory,
        link_website_blogspot: formValues.link_website_blogspot || link_website_blogspot,
        gdrive_file_document: formValues.gdrive_file_document || gdrive_file_document,
        link_embed_video: formValues.link_embed_video || link_embed_video,
      }
    },
    validationSchema: formValues.category === "writing" || category === 'writing' ?
      Yup.object().shape({
        name: Yup.string().required("Name is required"),
        phoneNumber: Yup.string().required("Phone number is required"),
        title: Yup.string().required("Title is required"),
        description: Yup.string().required("Description is required"),
        category: Yup.string().required("Category is required"),
        subCategory: Yup.string().required("Sub Category is required"),
        detailCategory: Yup.string().required("Detail Category is required"),
        link_website_blogspot: Yup.string().required("Link Website/Blogspot is required"),
        gdrive_file_document: Yup.string().required("Gdrive File Document is required"),
      })
    :
      Yup.object().shape({
        name: Yup.string().required("Name is required"),
        phoneNumber: Yup.string().required("Phone number is required"),
        title: Yup.string().required("Title is required"),
        description: Yup.string().required("Description is required"),
        category: Yup.string().required("Category is required"),
        subCategory: Yup.string().required("Sub Category is required"),
        detailCategory: Yup.string().required("Detail Category is required"),
        // link_website_blogspot: Yup.string().required("Link Website/Blogspot is required"),
        link_embed_video: Yup.string().required("Link Embed Video is required"),
      })
    ,
    handleSubmit(values) {
      setFormValues(values)
      console.log("VALUES_CHECK == ", values)
      // sendUpdateSubmissionData()
      const dataPhoneNumber = values.phoneNumber
      let formatPhoneNumber = ""

      if (dataPhoneNumber.startsWith("+62")) {
        formatPhoneNumber = dataPhoneNumber.split("+").join("")
      } else if (dataPhoneNumber.startsWith("62")) {
        formatPhoneNumber = dataPhoneNumber
      } else {
        formatPhoneNumber = "62"+dataPhoneNumber
      }

      // const formatPhoneNumber = values.phoneNumber.split("+").join("")
      console.log("VALUES_PHONE_CHECK == ", formatPhoneNumber)

      for (let i = 0; i < allUsers.length; i++) {
        console.log("--> 1")
        if (allUsers[i].phoneNumber == formatPhoneNumber) {
          console.log("--> 2")
          if (allUsers[i].title !== "" && userListingCategory.length > 0 && 
            userListingCategory.length < 2 ) {
            console.log("--> 3")
            if (userListingCategory.includes(values.category)) {
              console.log("--> 4")
              if (values.category === "writing") {
                console.log("--> 5")
                alert("Mohon maaf, identitas Anda telah terdaftar. Gunakan nomor telepon/alamat email lain, atau melalui kategori video")
                return false
              } else {
                console.log("--> 6")
                alert("Mohon maaf, identitas Anda telah terdaftar. Gunakan nomor telepon/alamat email lain, atau melalui kategori writing")
                return false
              }
            }
          } else if(allUsers[i].title !== "" && userListingCategory.length == 2 ) {
            console.log("--> 7")
            alert("Mohon maaf, identitas Anda telah terdaftar. Silahkan daftar dengan nomor telepon atau alamat email lain")
            return false
          }
        }
      }

      updateSubmission({
        variables: {
          fullName: values.name,
          phoneNumber: formatPhoneNumber,
          email: values.email,
          title: values.title,
          description: values.description,
          category: values.category.toString(),
          subCategory: values.subCategory.toString(),
          detailCategory: values.detailCategory.toString(),
          link_website_blogspot: values.category.toString() === "writing" ? values.link_website_blogspot : "",
          gdrive_file_document: values.category.toString() === "writing" ? values.gdrive_file_document.toString() : "",
          link_embed_video: values.category.toString() === "video" ? values.link_embed_video.toString() : "",
        }
      }).then(res => {
        console.log("submit2")
        console.log(res)
        console.log("response => ",res.data.updateWvc.status )
        if (res.data.updateWvc.status === "success") {
          console.log("successfull")
          const storedCategory = localStorage.getItem('selectedCategory');
          if (storedCategory) {
            localStorage.removeItem("selectedCategory")
          }
          window.location.href = "/wvc2023"
        }else{
          console.log("failed")
          console.log("failed_response => ",res.data.updateWvc.message )
        }
      })
    },
  })(LeadForm)

  useEffect(() => {
    if(location.uri === "/wvc2023/submission"){
      window.location.href = "/wvc2023"
    }
  }, [])

  useEffect(() => {
    let navbarDesktop = document.getElementById("desktopNav").querySelectorAll(".nav-link")
    let loginBtnSection = document.getElementById("desktopNav").querySelector(".nav-item.nav-desktop.ml-3")
    let loginBtnSectionMobile = document.getElementById("navbarMobile").querySelector(".navbar-toggle-wrapper.fixed-top .btn-login")
    let langSection = document.querySelector("#lang-wrapper")
      
    if(resizeScreen){
      document.getElementsByClassName("logo-secondary")[1].style.display = `none`
      document.getElementsByClassName("logo-main")[1].style.display = `block`
    }else{
      document.getElementsByClassName("logo-secondary")[0].style.display = `none`
      document.getElementsByClassName("logo-main")[0].style.display = `block`
    }

    for (let i = 0; i < navbarDesktop.length; i++) {
      navbarDesktop[i].style.color = "#000"
    }

    loginBtnSectionMobile.classList.add('d-none')
    loginBtnSection.classList.add('d-none')
    langSection.classList.remove('d-flex')
    langSection.classList.add('d-none')
    
    
  }, [])

  useEffect(() => {
    let cphoneNumber = getCookie("phoneNumber")
    phoneNumberRef.current = cphoneNumber

    let fullNameUpdated = allUsers !== undefined && allUsers.find(x => x.phoneNumber === phoneNumberRef.current).fullName
    let phoneNumberUpdated = allUsers !== undefined && allUsers.find(x => x.phoneNumber === phoneNumberRef.current).phoneNumber
    let titleUpdated = allUsers !== undefined && allUsers.find(x => x.phoneNumber === phoneNumberRef.current).title
    let descriptionUpdated = allUsers !== undefined && allUsers.find(x => x.phoneNumber === phoneNumberRef.current).description
    let linkUpdated = allUsers !== undefined && allUsers.find(x => x.phoneNumber === phoneNumberRef.current).link_website_blogspot
    let gdriveUpdated = allUsers !== undefined && allUsers.find(x => x.phoneNumber === phoneNumberRef.current).gdrive_file_document
    let uSubCat = allUsers !== undefined && allUsers.find(x => x.phoneNumber === phoneNumberRef.current).subCategory
    let uDetailCat = allUsers !== undefined && allUsers.find(x => x.phoneNumber === phoneNumberRef.current).detailCategory
    let phoneTrim = phoneNumberUpdated && phoneNumberUpdated.substring(2,14).trim()

    if (cphoneNumber === "" || cphoneNumber === undefined) {
      setWarningModal(true)
    }
  }, [])

  useLayoutEffect(() => {
    getUser()
  }, [])

  return(
    <Layout location={location} currentLocation={location.uri} isWvc={true}>
      <SEO 
        title={`Writing and Video Competition (WVC) 2023`}
        fbTitle={`Writing and Video Competition (WVC) 2023`}
        twitterTitle={`Writing and Video Competition (WVC) 2023`}
        description={`Writing and Video Competition 2023 by Sinar Mas Land. Nantikan puluhan hadiah dan hiburan dari Sinar Mas Land hanya di event ini!.`}
        fbDescription={`Writing and Video Competition 2023 by Sinar Mas Land. Nantikan puluhan hadiah dan hiburan dari Sinar Mas Land hanya di event ini!.`}
        twitterDescription={`Writing and Video Competition 2023 by Sinar Mas Land. Nantikan puluhan hadiah dan hiburan dari Sinar Mas Land hanya di event ini!.`}
        url={currentUrl}
        img={featuredImage}
        fbImg={featuredImage}
        twitterImg={featuredImage}
      />
      <Helmet>
        <meta 
          name="keywords" 
          content={seo?.focuskw ? seo?.focuskw : "Writing and Video Competition (WVC) 2023"}
        ></meta>
        <meta 
          name="copyright" 
          content="Writing and Video Competition (WVC) 2023"
        ></meta>
      </Helmet>
      <Section>
        <Container>
          <EnhancedLeadForm />
        </Container>
      </Section>
      <Modal
        isOpen={warningModal}
        onRequestClose={handleCloseWarningModal}
        style={customStyles}
        contentLabel="campaign modal"
        overlayClassName="modal-overlay-general"
      >
        <div className="modal-popup-otp modal-body text-center">
          <div className="container py-5 mw-100 mw-md-75">
            <h2>Peringatan!</h2>
            <p className="m-0">
              Silahkan untuk melakukan pengecekan nomor handphone terlebih dahulu untuk mengakses halaman ini.
            </p>
            <button
              className="btn btn-ecatalog-theme mt-4"
              onClick={handleCloseWarningModal}
            >
              Ok
            </button>
          </div>
        </div>
      </Modal>
    </Layout>
  )
}

export default SubmissionWvc2023Page